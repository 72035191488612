import {
	CART_ADD_ITEM,
	CART_REMOVE_ITEM,
	CART_SAVE_PAYMENT_METHOD,
	CART_SAVE_SHIPPING_ADDRESS,
} from '../constants/cart.constants';

export const cartReducer = (
	state = { cartItems: [], shippingAddress: {}, paymentMethod: '' },
	action
) => {
	switch (action.type) {
		case CART_ADD_ITEM:
			const item = action.payload;
			// product is going to be the id
			// check if item already exists in cart
			const itemAlreadyExists = state.cartItems.find(
				(x) => x.product === item.product
			);
			if (itemAlreadyExists) {
				return {
					...state,
					cartItems: state.cartItems.map((x) =>
						x.product === itemAlreadyExists.product ? item : x
					),
				};
			} else {
				return {
					...state,
					cartItems: [...state.cartItems, item],
				};
			}

		case CART_REMOVE_ITEM:
			return {
				...state,
				cartItems: state.cartItems.filter(
					(item) => item.product !== action.payload
				),
			};

		case CART_SAVE_SHIPPING_ADDRESS:
			return {
				...state,
				shippingAddress: action.payload,
			};

		case CART_SAVE_PAYMENT_METHOD:
			return {
				...state,
				paymentMethod: action.payload,
			};

		default:
			return state;
	}
};
