export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';

export const ORDER_GET_DETAILS_REQUEST = 'ORDER_GET_DETAILS_REQUEST';
export const ORDER_GET_DETAILS_SUCCESS = 'ORDER_GET_DETAILS_SUCCESS';
export const ORDER_GET_DETAILS_FAIL = 'ORDER_GET_DETAILS_FAIL';

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST';
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS';
export const ORDER_PAY_FAIL = 'ORDER_PAY_FAIL';
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET';

export const ORDER_LIST_SINGLE_USER_ORDERS_REQUEST =
	'ORDER_LIST_SINGLE_USER_ORDERS_REQUEST';
export const ORDER_LIST_SINGLE_USER_ORDERS_SUCCESS =
	'ORDER_LIST_SINGLE_USER_ORDERS_SUCCESS';
export const ORDER_LIST_SINGLE_USER_ORDERS_FAIL =
	'ORDER_LIST_SINGLE_USER_ORDERS_FAIL';

export const ORDER_RESET_LIST = 'ORDER_RESET_LIST';

export const ORDER_GET_ALL_REQUEST = 'ORDER_GET_ALL_REQUEST';
export const ORDER_GET_ALL_SUCCESS = 'ORDER_GET_ALL_SUCCESS';
export const ORDER_GET_ALL_FAIL = 'ORDER_GET_ALL_FAIL';

export const ORDER_DELIVER_REQUEST = 'ORDER_DELIVER_REQUEST';
export const ORDER_DELIVER_SUCCESS = 'ORDER_DELIVER_SUCCESS';
export const ORDER_DELIVER_FAIL = 'ORDER_DELIVER_FAIL';
export const ORDER_DELIVER_RESET = 'ORDER_DELIVER_RESET';
