export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET';

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL';
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET';

export const USERS_LIST_REQUEST = 'USERS_LIST_REQUEST';
export const USERS_LIST_SUCCESS = 'USERS_LIST_SUCCESS';
export const USERS_LIST_FAIL = 'USERS_LIST_FAIL';
export const USERS_LIST_RESET = 'USERS_LIST_RESET';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';

export const USER_GET_ANY_DETAILS_REQUEST = 'USER_GET_ANY_DETAILS_REQUEST';
export const USER_GET_ANY_DETAILS_SUCCESS = 'USER_GET_ANY_DETAILS_SUCCESS';
export const USER_GET_ANY_DETAILS_FAIL = 'USER_GET_ANY_DETAILS_FAIL';

export const USER_UPDATE_ANY_DETAILS_REQUEST =
	'USER_UPDATE_ANY_DETAILS_REQUEST';
export const USER_UPDATE_ANY_DETAILS_SUCCESS =
	'USER_UPDATE_ANY_DETAILS_SUCCESS';
export const USER_UPDATE_ANY_DETAILS_FAIL = 'USER_UPDATE_ANY_DETAILS_FAIL';
export const USER_UPDATE_ANY_DETAILS_RESET = 'USER_UPDATE_ANY_DETAILS_RESET';
